.page {
	padding: 40px 20px 20px 20px;
	background-color: #282c34;
	text-align: center
}

.not-found-logo {
	/*animation: App-logo-spin infinite 20s linear;*/
	height: 20vmin;
	pointer-events: none;
}

.slogan {
	font-weight: 'bold';
	/*font-size: 14px;*/
	font-size: calc(12px + 1vmin);
	margin-top: 20px;
	color: white;
}